<template>
  <div v-if="hasPermission" :id="id()" class="card">
    <div class="card-body py-1 text-center dx-swatch-dark">
      <div class="text-lg">
        {{ title }}
      </div>
      <div class="text-sm">
        {{ subtitle }}
      </div>
      <dx-chart :id="id" :data-source="dataSource" :palette="color" :loading-indicator="{enabled: true, backgroundColor: 'rgba(0,0,0,0.4)'}">
        <dx-series-template name-field="type" />
        <dx-common-series-settings
          argument-field="key"
          value-field="value"
          type="bar"
          :ignore-empty-points="false"
        />
        <dx-legend
          vertical-alignment="bottom"
          horizontal-alignment="center"
        />
        <dx-tooltip :enabled="true" location="edge" />
      </dx-chart>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-unused-vars */

import basicStatsService from '@/http/requests/stats/basicStatsService'
import moment from 'moment'
import DxChart, {
  DxSeriesTemplate,
  DxCommonSeriesSettings,
  DxLegend,
  DxTooltip,
} from 'devextreme-vue/chart'

export default {
  components: {
    'dx-chart': DxChart,
    'dx-series-template': DxSeriesTemplate,
    'dx-common-series-settings': DxCommonSeriesSettings,
    'dx-legend': DxLegend,
    'dx-tooltip': DxTooltip,
  },
  props: {
    id: {
      type: [String, Function],
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    requestData: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: () => null,
    },
    title: {
      type: String,
      required: false,
      default: () => null,
    },
    permission: {
      type: String,
      required: false,
      default: () => null,
    },
    color: {
      type: String,
      required: false,
      default: () => 'Material', //  'Bright' | 'Harmony Light' | 'Ocean' | 'Pastel' | 'Soft' | 'Soft Pastel' | 'Vintage' | 'Violet' | 'Carmine' | 'Dark Moon' | 'Dark Violet' | 'Green Mist' | 'Soft Blue' | 'Material' | 'Office'
    },
  },
  data() {
    return {
      dataSource: [],
      subtitle: '',
      initialLoad: false,
    }
  },
  computed: {
    hasPermission() {
      return this.permission ? this.$can('read', this.permission) : true
    },
    titleColor() {
      return this.$color
    },
  },
  created() {
    this.$parent.$on('update::search-filter', this.searchByFilter)
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    getRequest() {
      const { statsType } = this.requestData
      const currentYear = moment()
      const currentYearFormat = new Date(currentYear.endOf('month').format('L'))
      const lastThreeMonth = moment().subtract(3, 'month').add(1, 'day')
      const lastThreeMonthFormat = new Date(lastThreeMonth.startOf('month').format('L'))
      this.subtitle = `(${lastThreeMonth.startOf('month').format('L')} - ${currentYear.endOf('month').format('L')})`
      return {
        accountNo: '',
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: lastThreeMonthFormat,
        endDate: currentYearFormat,
        timeInterval: null,
        statsType: statsType,
        q: '',
        topN: null,
      }
    },
    async getData(filter) {
      this.dataSource.splice(0, this.dataSource.length)
      const filters = filter || this.getRequest()
      const result = await basicStatsService.getCombinedData(filters)
      this.dataSource = [...result.data]
      this.initialLoad = true
    },
    searchByFilter(accountNo, beginDate, endDate) {
      if (!this.initialLoad) {
        return
      }
      const filters = this.getRequest()
      filters.accountNo = accountNo
      filters.beginDate = beginDate
      filters.endDate = endDate
      this.getData(filters)
    },
  },
}
</script>

<style>
.text-lg {
  font-size: 26px;
  font-weight: 200;
}
.text-sm {
  font-size: 18px;
  font-weight: 200;
}
</style>
